import React, { Component } from 'react';
import SeoBase from '../../components/SeoBase';
import Header from '../../components/Header';
import Subheader from '../../components/Subheader';
import ContactSection from '../../components/ContactSection';
import Clients from '../../components/Clients';
import ColumnText from '../../components/ColumnText';
import Tripartite, { Item }  from '../../components/Tripartite';
import Banner from '../../components/Banner';
import ColumnsNote from '../../components/ColumnsNote';
import Depiction from '../../components/Depiction';
import ListLayout from '../../components/SeoComponents/ListLayout';
import LookLayout from '../../components/SeoComponents/LookLayout';
import Privacy from '../../components/Privacy'

import BaseLayout from '../../layouts/BaseLayout';

import chartIcon from '../../assets/chart.svg';
import toolsIcon from '../../assets/tools.svg';
import thumbIcon from '../../assets/thumb.svg';
import documentIcon from '../../assets/document.svg';
import arrowIcon from '../../assets/arrow.svg';
import repeatIcon from '../../assets/repeat.svg';

import calendarIcon from '../../assets/calendar.svg';
import podiumIcon from '../../assets/podium.svg';
import cardIcon from '../../assets/card.svg';
import seoBg from '../../assets/seo.svg';

const columnText = {
  title: 'Polityka prywatności serwisu yupi-there.pl',
  content: `
    1. Informacje ogólne
    <br />
    Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych
    osobowych przekazanych przez Użytkowników w związku z korzystaniem przez
    nich z usług <b>Yupi-There</b> poprzez Serwis.
    <br /><br />

    2. Administratorem danych osobowych zawartych w serwisie jest
    <b>Yupi-There Michał Jarmołowicz</b> z siedzibą w Warszawie przy
    ul. Darłowskiej 1A lok. 13, 04-091 Warszawa, NIP: 9661942666
    <br /><br />

    3. W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy
    wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych
    osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich
    zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych,
    ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju
    aktach wykonawczych i aktach prawa wspólnotowego.
    <br /><br />

    4. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez
    Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają
    Administratora do przetwarzania danych osobowych na podstawie przepisów
    prawa lub w celu realizacji zawartej pomiędzy stronami umowy.
    <br /><br />

    5. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich
    zachowaniach w następujący sposób:
    <br />
    poprzez dobrowolnie wprowadzone w formularzach informacje
    <br /
    poprzez gromadzenie plików “cookies”.
    <br /><br />

    6. Serwis zbiera informacje dobrowolnie podane przez użytkownika.
    <br /><br />

    7. Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji
    konkretnego formularza np. w celu dokonania procesu obsługi kontaktu
    informacyjnego.
    <br /><br />

    8. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani
    udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych
    osobowych.
    <br /><br />

    9. Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej,
    która je tam umieściła. Osoba ta ma również prawo do modyfikacji i
    zaprzestania przetwarzania swoich danych w dowolnym momencie.
    <br /><br />

    10. Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności
    Serwisu, na które może wpłynąć rozwój technologii internetowej, ewentualne
    zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu
    internetowego. O wszelkich zmianach będziemy informować w sposób widoczny
    i zrozumiały.
    <br /><br />

    11. W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie
    strony internetowe działają niezależnie od Serwisu i nie są w żaden sposób
    nadzorowane przez serwis <b>yupi-there.pl</b>. Strony te mogą posiadać własne
    polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się
    zapoznać.
    <br /><br />

    W razie wątpliwości dotyczących któregokolwiek z zapisów niniejszej Polityki
    Prywatności, jesteśmy do dyspozycji. Dane kontaktowe znaleźć można u dołu
    każdej podstrony Serwisu.
  `,
};

const websiteSchema = {
  name: "SEO",
  url: "https://yupi-there.netlify.com/privacy/",
}

class PrivacyPol extends Component {
  render() {
    return (
      <BaseLayout>
        <SeoBase
          title='Polityka prywatności – Yupi-There'
          description='Polityka prywatności w Yupi-There.'
          websiteSchema={ websiteSchema }
        >
          <Privacy { ...columnText } />

        </SeoBase>
      </BaseLayout>
    );
  }
}

export default PrivacyPol;
